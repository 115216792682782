module.exports = [{
      plugin: require('/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-111927056-1","anonymize":true},
    },{
      plugin: require('/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
