// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-me-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-expertise-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/expertise.js" /* webpackChunkName: "component---src-pages-expertise-js" */),
  "component---src-pages-index-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-serverless-computing-and-machine-learning-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/portfolio/serverless-computing-and-machine-learning.js" /* webpackChunkName: "component---src-pages-portfolio-serverless-computing-and-machine-learning-js" */),
  "component---src-pages-portfolio-virtual-reality-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/portfolio/virtual-reality.js" /* webpackChunkName: "component---src-pages-portfolio-virtual-reality-js" */),
  "component---src-pages-portfolio-voice-user-interface-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/portfolio/voice-user-interface.js" /* webpackChunkName: "component---src-pages-portfolio-voice-user-interface-js" */),
  "component---src-pages-recommendations-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-speaking-engagements-js": () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/src/pages/speaking-engagements.js" /* webpackChunkName: "component---src-pages-speaking-engagements-js" */)
}

exports.data = () => import("/Users/rhokstar/dev/jonagoot/jonagoot-gatsbyV2/.cache/data.json")

